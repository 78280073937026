<template>
  <div>    
    <v-container class="py-6" fluid>
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <external-data-results-table ref="DownloadExternalDataResultsTable"></external-data-results-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
  <script>
  
  import ExternalDataResultsTable from "./Widgets/DownloadExternalDataResultsTable.vue";
  
  export default {
    name: "ExternalDataResults",
    components: {
        ExternalDataResultsTable
    },
    data() {
      return {
      };
    },  
    methods:{
    
    }
  }
  </script>
  
  <style scoped>
  
  </style>