<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-card class="border-radius-xl">
      <v-card-text class="px-0 py-0">
        <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="results"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            class="table border-radius-xl"
            hide-default-footer
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar color="grey darken-3" flat height="80">
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <catalogs-expanded-list :highway-id="item.id" />
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 text-sm">Elementos por página:</span>
            <v-select
                v-model="itemsPerPage"
                dense
                filled
                solo
                :items="[5,10,20,50]"
            ></v-select>            
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
                class="pagination"
                next-icon="fa fa-angle-right"
                prev-icon="fa fa-angle-left"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import externalDataResultService from "@/services/externalDataResults.service";

export default {
  name: "DownloadExternalDataResultsTable",
  
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      results: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Recuento Creado",
          value: "createdCount",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Recuento Actualizado",
          value: "updatedCount",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Fecha de Finalización",
          value: "finishDate",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  watch:{
    itemsPerPage(){
      this.getExternalDataResults();
    },
    page(){
      this.getExternalDataResults();
    },
    pageCount(val){
      console.log(val)
    }
  },
  mounted() {
    this.getExternalDataResults();
  },
  methods:{
    getExternalDataResults(){
      this.isLoading = true;
      const offset = (this.page - 1) * this.itemsPerPage;

      this.results = [];

      externalDataResultService.getExternalDataResults(offset,this.itemsPerPage).then(d =>{
        this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
        this.results = d.items;

        this.isLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
  
