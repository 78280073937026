/* eslint-disable no-dupe-keys */
import http, { e } from './http'
import loginService from './login.service'

const externalDataResultsService = {  
  getExternalDataResults: function (offset,limit,filter,sorting) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };
    
    const data = {
      SkipCount: offset,
      MaxResultCount: limit
    }

    if(sorting)
      data['Sorting'] = sorting;

    if(filter)
      data['Filter'] = sorting;

    return http.get(e('app/download-external-data-result'), data, headers).then(r => {
      return r.data
    })
  },  
}

export default externalDataResultsService